import styled from 'styled-components';

const Button = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.primaryGreen};
  color: ${({ theme }) => theme.colors.primaryGreen};
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.bold};
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;

  :hover {
    background: ${({ theme }) => theme.colors.primaryGreen};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default Button;
