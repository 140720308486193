import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${({ fluid }) =>
    fluid ? '100%' : ({ theme }) => theme.media.desktop};
  margin: 0 auto;
  min-height: ${({ height }) => (height ? height : 'auto')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ flex }) =>
    flex
      ? `
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
  `
      : ``};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
`;

export default Wrapper;
