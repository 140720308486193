import React from 'react';
import styled from 'styled-components';
import Wrapper from './../Wrapper/Wrapper';
import ContactForm from './../Forms/ContactForm';
import Column from './../Column/Column';
import Map from './../Map/Map';

const StyledContactWrapper = styled(Wrapper)`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column-reverse;
  }
`;

const Contact = () => (
  <StyledContactWrapper fluid flex height="400px">
    {/* <Column width="50%"> */}
    <Map></Map>
    {/* </Column> */}
    {/* <Column width="50%">
      <ContactForm />
    </Column> */}
  </StyledContactWrapper>
);
export default Contact;
