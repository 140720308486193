import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Wrapper from './../components/Wrapper/Wrapper';
import Column from './../components/Column/Column';
import Button from './../components/Button/Button';
import Portfolio from './../components/Portfolio/Portfolio';
import Contact from './../components/Contact/Contact';

require('dotenv').config();

const StyledImage = styled(Column)`
  padding: 15px;
  img {
    margin-top: 40px;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
`;

const StyledWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.secondaryBlue};
  margin-top: -80px;
`;

const StyledHeroColumn = styled(Column)`
  padding: 15px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    text-align: center;
  }
`;

const StyledHeroHeader = styled.h1`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  letter-spacing: -1px;
  font-weight: ${({ theme }) => theme.fonts.bold};
  margin: 30px 0;
  line-height: 64px;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    font-size: 48px;
  }
`;

const StyledHeroParagraph = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.light};
`;

const StyledPortfolioWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const IndexPage = ({ data }) => (
  <>
    <StyledWrapper fluid>
      <Wrapper height="100vh" flex>
        <StyledImage width="50%" flex>
          <img src={data.file.publicURL} alt="Man washing hands" />
        </StyledImage>
        <StyledHeroColumn width="50%">
          <StyledHeroHeader>Razem zadbajmy o Twoją markę</StyledHeroHeader>
          <StyledHeroParagraph>
            Jesteśmy zespołem kreatywnym, który cechuje indywidualne podejście
            do klientów i metod reklamowych. Zależy Ci na współpracy z osobami,
            które znają się doskonale na tym co robią?
            <br /> Świetnie trafiłeś!
            <br />
            Oferujemy wiedzę, doświadczenie, zaangażowanie i partnerstwo we
            współpracy.
          </StyledHeroParagraph>
          <Button style={{ marginTop: '20px' }}>
            <Link
              to="#Portfolio"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Portfolio
            </Link>
          </Button>
        </StyledHeroColumn>
      </Wrapper>
    </StyledWrapper>
    <StyledPortfolioWrapper id="Portfolio">
      <Portfolio></Portfolio>
    </StyledPortfolioWrapper>
    <Contact />
  </>
);

export const query = graphql`
  {
    file(name: { eq: "undraw_wash_hands_nwl2" }) {
      publicURL
    }
  }
`;

export default IndexPage;
