import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import axios from 'axios';
import Button from './../Button/Button';

require('dotenv').config();

//const webhook = 'https://hook.integromat.com/3vdjvj9kcsjuxbwa9i3te9mgipx7d8ug';
const webhook = `https://hook.integromat.com/${process.env.GATSBY_INTEGROMAT_API_KEY}`;

const StyledForm = styled.form`
  padding: 0 15px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
    margin: 40px auto;
  }
`;

const StyledPostForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fonts.bold};
  font-size: 28px;
  color: ${({ theme }) => theme.colors.secondaryBlue};
  min-height: 400px;
  padding: 0 15px;
  p {
    text-align: center;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  min-height: 36px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
  &:notvalid {
    border-color: red;
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.secondaryBlue};
`;

const StyledMessage = styled.textarea`
  width: 100%;
  min-height: 120px;
  margin-bottom: 20px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
`;

const StyledComunicates = styled.div`
  color: red;
`;

const StyledButton = styled(Button)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
  color: ${({ theme }) => theme.colors.secondaryBlue};
  &:hover {
    color: white;
    border: none;
    background-color: ${({ theme }) => theme.colors.secondaryBlue};
  }
`;

const ContactForm = () => {
  let [visible, setVisible] = useState(true);
  let [send, setSend] = useState(false);

  return (
    <>
      {visible ? (
        <Formik
          initialValues={{ name: '', email: '', phone: '', message: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Pole wymagane';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Podaj prawidłowy adres email';
            }

            if (!values.name) {
              errors.name = 'Pole wymagane';
            }

            if (!values.message) {
              errors.message = 'Pole wymagane';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
            // {
            //   console.log(values);
            // }
            axios.post(webhook, { values }).then(res => {
              setSubmitting(false);
              setVisible(false);
              if (res.data == 'success') {
                setSend(true);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <StyledLabel htmlFor="name">
                Imię
                <StyledComunicates>
                  {errors.name && touched.name && errors.name}
                </StyledComunicates>
              </StyledLabel>
              <StyledInput
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                autoComplete="on"
              />

              <StyledLabel htmlFor="email">
                E-mail
                <StyledComunicates>
                  {errors.email && touched.email && errors.email}
                </StyledComunicates>
              </StyledLabel>
              <StyledInput
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="on"
              />

              <StyledLabel htmlFor="phone">Telefon</StyledLabel>
              <StyledInput
                type="phone"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                autoComplete="on"
              />

              <StyledLabel htmlFor="message">
                Wiadomość
                <StyledComunicates>
                  {errors.message && touched.message && errors.message}
                </StyledComunicates>
              </StyledLabel>
              <StyledMessage
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              <StyledButton type="submit" disabled={isSubmitting}>
                Wyślij
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <StyledPostForm>
          {send ? (
            <>
              <p>Dziękujemy za wysłanie wiadomości.</p>
              <p> Odpowiemy w najszybszym możliwym terminie.</p>
            </>
          ) : (
            <>
              <p style={{ fontColor: 'red' }}>
                Niestety nie udało się wysłać wiadomości.
              </p>
              <p style={{ fontColor: 'red' }}>
                Spróbuj pownie później, albo użyj innych kanałów komunikacji.
              </p>
            </>
          )}
        </StyledPostForm>
      )}
    </>
  );
};

export default ContactForm;
