import styled from 'styled-components';

const Column = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  min-height: ${({ height }) => (height ? height : 'auto')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ flex }) =>
    flex
      ? `
    justify-content: center;
    align-items: center;
  
  `
      : ``};

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
  }
`;

export default Column;
